<template>
  <div>
    <saveBtn :settings="{block: true}" v-if="data.form" :onClick="function () { setSaveCalendar() }"><template v-slot:text>{{ $t('message.save', {name: $tc('message.calendar', 1)}) }}</template></saveBtn>
    <transition name="flixFadeIn">
      <div :key="show + error">
        <div v-if="show" class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
        <div v-if="error" class="flix-alert flix-alert-danger" v-html="error"></div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, setNotLogin: Function },
  data () {
    return {
      show: false,
      error: false,
      variables: {}
    }
  },
  methods: {
    getID () {
      if (typeof this.data.ID !== 'undefined') {
        return this.data.ID
      }
      return this.$route.params.id
    },
    setTimeoutShow () {
      setTimeout(function () {
        this.show = false
        this.error = false
      }.bind(this), 3000)
    },
    setSaveCalendar () {
      if (this.variables.user === false) {
        this.setNotLogin(function () { this.variables = this.$getUserVariables(); this.data.ID = 'new'; this.setSaveCalendarNow() }.bind(this))
      } else {
        this.setSaveCalendarNow()
      }
    },
    setStandardReminder (id) {
      this.$store.dispatch('setStandardReminder', {
        user: this.variables.user.md5_id,
        ID: id,
        callback: function (ret) { /* window.console.log(ret) */ }
      })
    },
    setSaveCalendarNow () {
      this.error = false
      this.$store.dispatch('setAssistent', {
        ID: this.getID(),
        data: this.data,
        callback: function (ret) {
          if (ret.data[0]) {
            this.updateStore(ret.data[1])
            this.setStandardReminder(ret.data[1].ID)
            this.show = true
            this.setTimeoutShow()
          } else {
            if (typeof ret.data[1].msg !== 'undefined') {
              this.error = ret.data[1].msg
            } else {
              this.error = ret.data[1]
            }
            this.setTimeoutShow()
          }
        }.bind(this)
      })
    },
    setSendUserEmail () {
      this.variables = this.$getUserVariables()
      var embedCode = this.$getEmbedCode({ user: this.variables.user.md5_id, ID: this.data.ID, language: this.variables.user.language })
      var link = this.$flix_url + '/' + this.variables.user.language + '/' + this.variables.user.username + '/' + this.data.ID + '/index.html'
      var editLink = this.$flix_url + '/calendar-assistent/' + this.data.ID + '.html'
      var send = {
        user: this.variables.user.md5_id,
        to: this.variables.user.email,
        title: this.$root.$t('email.newAssistentEmail.title').split('{name}').join(this.data.title),
        text: this.$root.$t('email.newAssistentEmail.text')
          .split('{name}').join(this.data.title)
          .split('{link}').join('<a href="' + link + '">' + link + '</a>')
          .split('{embedcode}').join('<code style="overflow-wrap: break-word; word-break: break-all;">' + embedCode.split('<').join('&lt;').split('[').join('&lt;').split(']').join('&gt;').split('>').join('&gt;') + '</code>')
          .split('{editLink}').join('<a href="' + editLink + '">' + editLink + '</a>')
          .split('{supportcenter}').join('<a href="' + this.$flix_url + '/support-chat.html">' + this.$flix_url + '/support-chat.html</a>')
      }
      this.$flix_post({
        data: send,
        url: 'assistent/sharingmail',
        callback: function () { }
      })
      return send
    },
    setChangeUrl (ret) {
      if (this.$route.params.id === 'new') {
        // this.$store.getters.assistents
        this.data.ID = ret.ID
      }
    },
    updateStore (ret) {
      var assistents = JSON.parse(JSON.stringify(this.$store.getters.assistents))

      if (typeof assistents !== 'object') {
        assistents = []
      }
      var newAssistent = true
      var data = JSON.parse(JSON.stringify(this.data))
      if (ret.ID !== null) {
        data = Object.assign(this.data, { ID: ret.ID.toString() })
      }
      if (Object.keys(assistents).length) {
        Object.keys(assistents).forEach(function (k) {
          if (assistents[k].ID.toString() === ret.ID.toString()) {
            assistents[k] = data
            if (typeof data.styling === 'object' && typeof data.styling.accent === 'string') {
              assistents[k].color = data.styling.accent
            }
            newAssistent = false
          }
        })
      }

      if (newAssistent) {
        assistents.splice(0, 0, data)
        if (typeof assistents[0] !== 'undefined' && typeof data.styling === 'object' && typeof data.styling.accent === 'string') {
          assistents[0].color = data.styling.accent
        }
        this.setSendUserEmail()
      }

      this.$store.commit('setAssistents', assistents)
      this.setChangeUrl(ret)
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
    // this.setSaveCalendar()
    // this.setTimeoutShow()
  },
  computed: {

  }
}
</script>
